import { useEffect, useState } from "react";
import "./Booking.css";
import { useNavigate, useParams } from "react-router-dom";
import { fetchActivity } from "../../helper/ResultCalc";
import { LoadingDialog } from "../basicpages/LoadingDialog";
import { pdf } from "@react-pdf/renderer";
import { PDFCreator } from "./PDFCreator";

export function Booking() {
  const { actID } = useParams();

  const [loading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState({
    price: 0,
    title: "",
    startImage: { large: "" },
  });
  const [datum, setDatum] = useState("");
  const [personen, setPersonen] = useState(1);
  const [personError, setPersonError] = useState("");
  const [dateError, setDateError] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [vorname, setVorname] = useState("");

  const minDate = new Date().toISOString().split("T")[0];
  const in3years = new Date();
  in3years.setFullYear(new Date().getFullYear() + 3);
  const maxDate = in3years.toISOString().split("T")[0];

  const minPerson = 1;
  const maxPerson = 25;

  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchData() {
      try {
        const activity = await fetchActivity(actID + ".json", signal);
        setActivityData(activity);
        setLoading(false);
      } catch (err) {
        if (err instanceof SyntaxError) {
          navigate("/404");
        }
        console.error(err);
      }
    }
    fetchData();
    return () => {
      // cancel the request before component unmounts
      controller.abort();
    };
  }, [actID, navigate]);

  const handlePersonChange = (e) => {
    const newValue = Number.parseInt(e.target.value);
    if (newValue < minPerson || newValue > maxPerson) {
      e.preventDefault();
      setPersonError(
        `Mindestens ${minPerson} und maximal ${maxPerson} Personen.`
      );
    } else {
      setPersonError("");
      setPersonen(newValue);
    }
  };

  const handleDateChange = (e) => {
    const newValue = e.target.value;
    setDatum(newValue);
    if (
      new Date(newValue).getTime() < new Date(minDate).getTime() ||
      new Date(newValue).getTime() > new Date(maxDate).getTime()
    ) {
      setDateError(
        `Das Datum muss zwischen ${formatDate(minDate)} und ${formatDate(
          maxDate
        )} liegen.`
      );
    } else {
      setDateError("");
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    pdf(
      <PDFCreator
        activityData={activityData}
        formData={{
          datum: formatDate(datum),
          personen: personen,
          email: email,
          name: name,
          vorname: vorname,
        }}
      />
    )
      .toBlob()
      .then((blob) => {
        console.log(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = "buchungsbestätigung_" +activityData.id + "_" + datum + ".pdf";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        console.log("Formular wurde gesendet!", {
          datum: formatDate(datum),
          personen,
          email,
          name,
          vorname,
        });
        navigate("/confirm");
      });
  };

  function formatDate(inputDate) {
    const dateParts = inputDate.split("-");
    const formattedDate = `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
    return formattedDate;
  }

  return (
    <>
      {loading === true && <LoadingDialog />}
      <article className="booking bg-cyprus rounded-[1.3rem] mx-4 sm:mx-auto my-16 max-w-[500px] md:max-w-fit drop-shadow-2xl border-white border-2 flex md:flex-row flex-col-reverse">
        <form
          className="max-w-[500px] md:max-w-[350px] bg-[#FCFCFC] p-5 rounded-2xl"
          onSubmit={handleFormSubmit}
        >
          <label>
            Vorname:
            <input
              type="text"
              value={vorname}
              required
              onChange={(e) => setVorname(e.target.value)}
            />
          </label>

          <label>
            Nachname:
            <input
              type="text"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </label>

          <label>
            Reisedatum:
            <input
              type="date"
              value={datum}
              min={minDate}
              max={maxDate}
              required
              onChange={handleDateChange}
            />
            <p className="text-sm text-red-500">{dateError}</p>
          </label>

          <label>
            Personen:
            <input
              type="number"
              value={personen}
              min={minPerson}
              max={maxPerson}
              required
              onChange={handlePersonChange}
            />
            <p className="text-sm text-red-500">{personError}</p>
          </label>

          <label>
            Email:
            <input
              type="email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <button
            type="submit"
            className="bg-[#A97142] text-white w-full cursor-pointer text-base mt-4 px-[15px] py-2.5 rounded-2xl border-[none] standard-secondary-button"
          >
            Buchen
          </button>
        </form>
        <section className="flex flex-col justify-items-center p-8 max-w-[500px] min-h-[450px] md:min-h-fit md:w-[450px]">
          <h1 className="text-white text-3xl font-semibold font-poppins">
            Reservierung
          </h1>

          <p className="text-white text-lg font-light mt-10">
            Reservieren Sie noch jetzt und erleben Sie einen der schönsten
            Momente!
          </p>

          <h2 className="text-white text-2xl font-normal mt-auto">
            {activityData.title}
          </h2>
          <h2 className="text-white text-2xl font-normal mb-auto">
            {personen} {personen === 1 ? "Person" : "Personen"}
          </h2>

          <p className="text-white text-xl font-normal font-poppins mt-auto mb-2 w-full">
            Preis:{" "}
            <span className="float-right">{activityData.price}€ p. P.</span>
          </p>
          <p className="text-white text-3xl font-medium font-poppins w-full">
            Gesamt:{" "}
            <span className="float-right">
              {(activityData.price * personen).toFixed(2)}€
            </span>
          </p>
        </section>
      </article>
    </>
  );
}
