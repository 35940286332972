import { useEffect, useState } from "react";
import { fetchActivities } from "../../helper/ResultCalc";
import { getItemsFromData } from "../../helper/ItemFromData";
import {LoadingDialog} from "../basicpages/LoadingDialog";
import { ScrollToTopButton } from "../ScrollToTopButton";


export function AllActivities() {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchData() {
      try {
        const activities = await fetchActivities(signal);
        setData(activities.all);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    fetchData();

    return () => {
      // cancel the request before component unmounts
      controller.abort();
    };
  }, []);

  return (
    <>
    <ScrollToTopButton />
    <article className="bg-gray-200 flex flex-wrap gap-5 justify-around p-4 ">
        {loading === true && <LoadingDialog />}
        {getItemsFromData(data)}
    </article>
    </>
  );
}
