import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
// Create styles
const styles = StyleSheet.create({
  logo: {
    width: 200,
  },
  page: {
    backgroundColor: "white",
  },
  sectionBlue: {
    color: "white",
    padding: "30",
    backgroundColor: "#046C95",
  },
  section: {
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: "white",
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  contactInfo: {
    marginTop: 50,
  },
  p: {
    color: "#444444",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: 12,
  },
  h1: {
    color: "#046C95",
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: 28,
  },
  h2: {
    fontFamily: "Comfortaa",
    color: "#000000",
    fontWeight: "400",
    fontSize: 24,
    marginTop: 20,
  },
  h3: {
    fontFamily: "Poppins",
    color: "#000000",
    fontWeight: "400",
    fontSize: 14,
    marginTop: 20,
  },
});

export function PDFCreator({ activityData, formData }) {
  Font.register({
    family: "Comfortaa",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/comfortaa/v45/1Ptsg8LJRfWJmhDAuUsISotrDfGGxA.ttf",
        fontWeight: "400",
      },
    ],
  });

  Font.register({
    family: "Poppins",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTed3FBGPaTSQ.ttf",
        fontWeight: "thin",
      },
      {
        src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_V1tvFP-KUEg.ttf",
        fontWeight: "ultralight",
      },
      {
        src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8V1tvFP-KUEg.ttf",
        fontWeight: "light",
      },
      {
        src: "https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
        fontWeight: "400",
      },
      {
        src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf",
        fontWeight: "medium",
      },
      {
        src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf",
        fontWeight: "semibold",
      },
      {
        src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf",
        fontWeight: "bold",
      },
      {
        src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4V1tvFP-KUEg.ttf",
        fontWeight: "ultrabold",
      },
      {
        src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5V1tvFP-KUEg.ttf",
        fontWeight: "heavy",
      },
    ],
  });

  return (
    <Document>
      {/*render a single page*/}
      <Page size="A4" style={styles.page}>
        <View style={styles.sectionBlue}>
          <Image
            style={styles.logo}
            src={
              process.env.PUBLIC_URL +
              "/assets/images/logo/175h/Logo_weiss_schrift_175h.png"
            }
          />
        </View>
        <View style={{ ...styles.section, paddingTop: 30 }}>
          <Text style={styles.h1}>Bestätigung Deiner Buchung</Text>
          <View style={styles.contactInfo}>
            <Text style={styles.p}>
              {formData.vorname} {formData.name}
            </Text>
            <Text style={styles.p}>{formData.email}</Text>
          </View>
        </View>
        <View style={{ ...styles.section, marginTop: 40 }}>
          <Text style={styles.h2}>{activityData.title}</Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text style={styles.h3}>
              {formData.personen} Personen am {formData.datum}
            </Text>
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: 12,
                }}
              >
                <Text style={{ ...styles.h3, fontWeight: 800, width: "2.8cm",textAlign: "center" }}>
                  Personen
                </Text>
                <Text style={{ ...styles.h3, fontWeight: 800, width: "2.8cm",textAlign: "center" }}>
                  Preis p. P.
                </Text>
                <Text style={{ ...styles.h3, fontWeight: 800, width: "2.8cm",textAlign: "center" }}>
                  Gesamt
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text style={{ ...styles.h3, marginTop: 8, width: "2.8cm",textAlign: "center" }}>
                  {formData.personen}
                </Text>
                <Text
                  style={{
                    ...styles.h3,
                    marginTop: 8,
                    width: 12,
                    textAlign: "center",
                  }}
                >
                  *
                </Text>
                <Text style={{ ...styles.h3, marginTop: 8, width: "2.8cm",textAlign: "center" }}>
                  {activityData.price}€
                </Text>
                <Text
                  style={{
                    ...styles.h3,
                    marginTop: 8,
                    width: 12,
                    textAlign: "center",
                  }}
                >
                  =
                </Text>
                <Text style={{ ...styles.h3, marginTop: 8, width: "2.8cm" ,textAlign: "center" }}>
                  {(activityData.price * formData.personen).toFixed(2)}€
                </Text>
              </View>
            </View>
          </View>
        </View>
        <Image style={{marginTop: "1.5cm", height: "13.7cm", padding: 30}} src={process.env.PUBLIC_URL +
              activityData.startImage.large}/>
      </Page>
    </Document>
  );
}
