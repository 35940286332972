import styled from "@emotion/styled";
import Chip from "@mui/material/Chip";
import {getIcon} from "../../helper/CategoryIcons";
import "./ResultItem.css"
import { Link } from "react-router-dom";


export function ResultItem({alt, src, title, description, categories, id}) {

    const GreyChip = styled(Chip)({
        background: "#ededed",
        color: "#686868",
        border: "1px solid #efefef",
        "& .MuiChip-icon": {
            color: "#686868",
        }
    });

    return (
        <article className={"result-item bg-white rounded-2xl flex-shrink-0 h-auto"}>
            <Link to={`/activity/${id}`} className=" outline-amber-300  rounded-2xl">
                <img src={src} alt={alt} className={"rounded-t-2xl h-[200px] w-full object-cover"}/>
                <section className="content p-4 min-h-[300px]">
                    <h4 className="font-bold font-poppins text-lg">{title}</h4>
                    <p className="mt-2 text-[15px]">{description}</p>
                        <div className="mt-auto">{
                            categories.map((category) => {
                                return <GreyChip size={"small"} sx={{marginRight: "0.5rem", marginTop: "0.5rem"}} key={category} icon={getIcon(category)} label={category}/>
                            })}
                        </div>
                </section>
            </Link>        
        </article>
    )
}