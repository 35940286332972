import "./SearchResult.css";
import { useEffect, useRef, useState, useCallback } from "react";
import { IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getItemsFromData } from "../../helper/ItemFromData";

export default function SearchResult({ displayData, displayText }) {
  const [slideContent, setRef] = useHookWithRefCallback();

  const mobile = useMediaQuery("(max-width: 768px)");

  const [counter, setCounter] = useState(0);

  const [actItems, setActItems] = useState(getItemsFromData(displayData));

  const [scrollNeeded, setScrollNeeded] = useState(false);

  const itemWidth = 300;
  const gap = 1.5 * 16;

  useEffect(() => {
    setActItems(getItemsFromData(displayData));
    moveTo(0);
// eslint-disable-next-line 
  }, [displayData, mobile]);

  

  function moveLeft() {
    slideContent.current.scrollLeft -= itemWidth + gap;
    if (counter > 0) {
      setCounter(counter - 1);
    }
  }

  function moveRight() {
    slideContent.current.scrollLeft += itemWidth + gap;
    if (counter < actItems.length - 1) {
      setCounter(counter + 1);
    }
  }

  function moveTo(index) {
    if (slideContent.current) {
      slideContent.current.scrollLeft = index * itemWidth + index * gap;
      setCounter(index);
    }
  }

  function useHookWithRefCallback() {
    const ref = useRef(null);
    const setRef = useCallback((node) => {
      const handleNode = () => {
        if (node.scrollWidth - node.clientWidth === 0) {
          setScrollNeeded(false);
        } else {
          setScrollNeeded(true);
        }
      };
      if (ref.current) {
        // Make sure to cleanup any events/references added to the last instance
        window.removeEventListener("resize", handleNode);
      }

      if (node) {
        handleNode();

        //observe when children changed
        const observer = new MutationObserver(handleNode);
        observer.observe(node, { childList: true, subtree: true });

        //resize listener
        window.addEventListener("resize", handleNode);
      }
      // Save a reference to the node
      ref.current = node;
    }, []);

    return [ref, setRef];
  }

  const iconStyle = { width: "56px", height: "56px", color: "White" };
  const buttonStyle = { width: "60px", height: "60px", zIndex: "10" };

  if (actItems.length === 0) {
    return (
      <section className="items-center flex flex-col gap-6 my-8">
        <hr className="w-full"></hr>
        <h1 className="text-center text-6xl text-white font-black">Ohh!</h1>
        <h2 className="text-center text-xl text-black w-3/5">
          Leider haben wir keine Reiseziele, die zu deinen Wünschen passen,
          gefunden. Bitte ändere deine Auswahlkriterien.
        </h2>
      </section>
    );
  }

  return (
    <>
      <h1 className="text-black text-center mb-2 text-xl my-4">
        {displayText}
      </h1>
      <section className="result-item-list relative">
        {!mobile && counter !== 0 && scrollNeeded && (
          <IconButton
            className="button-left absolute"
            aria-label="navigate to left"
            onClick={moveLeft}
            size="large"
            sx={buttonStyle}
          >
            <ChevronLeft sx={iconStyle} />
          </IconButton>
        )}

        {!mobile && counter < actItems.length - 1 && scrollNeeded && (
          <IconButton
            className="button-right absolute"
            aria-label="navigate to right"
            onClick={moveRight}
            size="large"
            sx={buttonStyle}
          >
            <ChevronRight sx={iconStyle} />
          </IconButton>
        )}

        <div
          className="slide-content flex md:flex-row flex-col gap-6 overflow-hidden md:px-10 items-center md:items-start py-2"
          ref={setRef}
        >
          {actItems}
        </div>
        {!mobile && scrollNeeded && (
          <section className="item-nav-list flex w-fit mx-auto mt-4 gap-2 bg-white px-2 py-1.5 rounded-3xl">
            {actItems.map((item, index) => {
              return (
                <div
                  key={"nav-button_" + index}
                  className={`result-nav-button w-[14px] h-[14px] bg-gray-300 rounded-[50%] cursor-pointer${
                    counter === index ? " active" : ""
                  }`}
                  onClick={() => {
                    moveTo(index);
                  }}
                ></div>
              );
            })}
          </section>
        )}
      </section>
    </>
  );
}
