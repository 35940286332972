import "./kontakt.css"
import React, { useState, useEffect } from 'react';
import {useNavigate} from "react-router-dom";

export function Kontakt() {

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [vorname, setVorname] = useState('');
    const [text, setText] = useState('');
    const [option, setOption] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
      setOption([]);
    }, []); 
  
    const handleOptionChange = (e) => {
      setOption([e.target.value]);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        navigate("/anfrage");

    };
          

    return (
        <div className="background">
            <div className="contact relative w-[90%] md:w-[80%] xl:w-[60%] bg-[#e6e6e6] my-[5%] m-auto rounded-[20px] border-0 shadow-2xl" >
                <article className="relative w-[85%] mx-auto py-[40px]">
                        <h1>Kontakt.</h1>
                <form onSubmit={handleFormSubmit}>
                <label>
                    <h3>Vorname:</h3>
                    <input type="text" maxlength="30" value={vorname} required onChange={(e) => setVorname(e.target.value)}/>
                </label>
                <label>
                    <h3>Nachname:</h3>
                    <input type="text" maxlength="30" value={name} required onChange={(e) => setName(e.target.value)}/>
                </label>
                <label>
                    <h3>E-Mail:</h3>
                    <input type="text" maxlength="40" value={email} required onChange={(e) => setEmail(e.target.value)}/>
                </label>
                <article>
                    <h3>Worum geht es?</h3>
                    <label>
                    <select multiple size="3" value={option} required onChange={handleOptionChange} >
                        <option value="val1">Weitere Fragen zu einer oder mehr Aktivitäten</option>
                        <option value="val2">Fragen zum Buchungsprozess und zur Abrechnung</option>
                        <option value="val3">Probleme mit der Webseite oder beim Navigieren</option>
                        <option value="val4">Sonstiges</option>
                    </select>
                    </label>
                </article>
                <article>
                    <h3>Deine Nachricht an uns.</h3>
                    <textarea type="text" rows="5" cols={3} maxLength="300" value={text} required onChange={(e) => setText(e.target.value)}></textarea>
                </article>
                <article>
                <button type="button" className="standard-button" onClick={handleFormSubmit}> {" "} Senden {" "}  </button>
                </article>
                </form>
                </article>

        </div>
        </div>
        
    )
}