import { ResultItem } from "../components/searchResult/ResultItem";

export function getItemsFromData(data) {
    return data.map((currentAct, index) => {
      return (
        <ResultItem
          id={currentAct.id}
          key={currentAct.title}
          src={currentAct.startImage.small}
          alt={currentAct.startImage.alt}
          categories={currentAct.categories}
          description={currentAct.shortDescription}
          title={currentAct.title}
        />
      );
    });
  }