import "./App.css";

import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import Detail from "./components/detail/Detail";
import {Landingpage} from "./components/landingpage/Landingpage";
import {Route, Routes} from "react-router-dom";
import {PageNotFound} from "./components/basicpages/PageNotFound";
import {Kontakt} from "./components/basicpages/Kontakt";
import {AboutUs} from "./components/basicpages/AboutUs";
import {Booking} from "./components/booking/Booking";
import {Confirm} from "./components/basicpages/Confirm";
import {Kontaktanfrage} from "./components/basicpages/Kontaktanfrage";
import { AllActivities } from "./components/allActivities/AllActivities";

function App() {

    return (
        <>
            <Navbar/>
            <main>
                <Routes >
                    <Route exact path={"/"} element={<Landingpage />} />
                    <Route exact path={"/kontakt"} element={<Kontakt />} />
                    <Route exact path={"/about-us"} element={<AboutUs />} />
                    <Route exact path={"/angebote"} element={<AllActivities />} />
                    <Route exact path={"/activity/:actID"} element={<Detail />} />
                    <Route exact path={"/reservate/:actID"} element={<Booking />} />
                    <Route exact path={"/confirm"} element={<Confirm />} />
                    <Route exact path={"/anfrage"} element={<Kontaktanfrage />} />
                    <Route path={"*"} element={<PageNotFound />}/>
                </Routes>
            </main>
            <Footer/>
        </>
    );
}

export default App;
