import VerifiedIcon from '@mui/icons-material/Verified';
export function Confirm() {

    return (
    <article
        className="bg-white rounded-[1.3rem] mx-auto my-32 w-fit drop-shadow-2xl border-gray-300/30 p-8 border-2 flex flex-col items-center">
            <VerifiedIcon sx={{height: "96px", width: "96px", color: "#6bff6b"}}/>
            <h1 className="text-3xl font-semibold mt-8 text-center">Ihre Reise wurde bestätigt!</h1>
            <p className="text-lg font-light mt-4 text-center">Wir freuen uns auf das nächste Mal.</p>
    </article>
    )
}