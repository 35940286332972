import LandscapeIcon from "@mui/icons-material/Landscape";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import ForestIcon from "@mui/icons-material/Forest";
import ExploreIcon from "@mui/icons-material/Explore";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import SportsIcon from "@mui/icons-material/Sports";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import GroupsIcon from '@mui/icons-material/Groups';
import AccessibleIcon from "@mui/icons-material/Accessible";

export function getIcon(category) {
    switch (category) {
        case 'Berge':
            return <LandscapeIcon />;
        case 'Stadt':
            return <LocationCityIcon />;
        case 'Strand':
            return <BeachAccessIcon />;
        case 'Abenteuer':
            return <SportsIcon />;
        case 'Entspannung':
            return <SelfImprovementIcon />;
        case 'Entdecken':
            return <ExploreIcon />;
        case 'Natur':
            return <ForestIcon />;
        case 'Allein':
            return <PersonIcon />;
        case 'Paar':
            return <GroupIcon />
        case 'Gruppe':
            return <GroupsIcon />;
        case 'Warm':
            return <WbSunnyIcon />;
        case 'Kalt':
            return <AcUnitIcon />;
        case 'Barrierefrei':
            return <AccessibleIcon />;
        default:
            console.warn("This category does not exist!!!! " + JSON.stringify(category))
            // Fallback in case the label doesn't match any case
            return null;
    }
}