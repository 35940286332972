import Chip from "@mui/material/Chip";
import styled from "@emotion/styled";
import "./SearchBox.css"
import { Fragment } from "react";
import {getIcon} from "../../helper/CategoryIcons";

export default function SearchBox({updateAppState, chips}) {
    
      const activeColor = "#33d6ff";
      const textColor = "#5f696b";
      const textColorActive = "#fff";
    
      const WhiteChip = styled(Chip)({
        background: "white",
        color: textColor,
        border: "1px solid #efefef",
        "&.active": {
          backgroundColor: activeColor,
          color: textColorActive,
        },
        "& .MuiChip-icon": {
          color: textColor,
        },
        "&.active .MuiChip-icon": {
          color: textColorActive,
        },"&.active:focus-visible, &.active:hover": {
          background: "#2cb7da",
        },
        "&:focus-visible, &:hover": {
          background: "#cececeb0",
        },
      });
    
      function clickChip(indexCat, indexChip) {
        if (indexCat != null && indexChip != null) {
          const newChips = [...chips];
          newChips[indexCat].chips[indexChip].checked = !newChips[indexCat].chips[indexChip].checked;
          updateAppState({categories: chips, newChips: newChips});
        }
      }

    return (
        <>
        <h1 className="text-black mb-2 text-lg">
        Worauf hast Du Lust?
      </h1>
      <section className="filter-chips flex gap-2 md:justify-between justify-center">
        {/*Hier werden die Chips gerendert*/}
        {chips.map((currentCategory, indexCat) => {
          return (
            <Fragment key={indexCat}>

                {currentCategory.chips.map((currentChip, indexChip) => {
                  return (
              

                    <WhiteChip
                      className={currentChip.checked ? "active" : ""}
                      key={indexCat + "_" + indexChip}
                      icon={getIcon(currentChip.label)}
                      label={currentChip.label}
                      onClick={() => clickChip(indexCat, indexChip)}
                      sx={{
                        backgroundColor: "primary.main",
                        color: "text.primary",
                      }}
                    />
                  
                  );
                })}
            </Fragment>
          );
        })}

      </section>
      </>
    )
}