import VerifiedIcon from '@mui/icons-material/Verified';
import {useNavigate} from "react-router-dom";
import "./Kontaktanfrage.css";

export function Kontaktanfrage() {

    const navigate = useNavigate();

    const navStart = (e) => {
        navigate("/");

    };

    return (
    <article
        className="contact_confirm bg-white rounded-[1.3rem] mx-auto my-32 w-fit drop-shadow-2xl border-gray-300/30 p-8 border-2 flex flex-col items-center">
            <VerifiedIcon sx={{height: "96px", width: "96px", color: "#6bff6b"}}/>
            <h1 className="text-3xl font-semibold mt-8 text-center">Dein Anliegen ist bei uns eingegangen!</h1>
            <p className="text-lg font-light mt-4 text-center">Wir bearbeiten deine Anfrage und melden uns zeitnah bei dir.</p>
            <button className='standard-button' onClick={navStart}> Zur Startseite  </button>
    </article>
    )
}