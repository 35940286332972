import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchActivity } from "../../helper/ResultCalc";
import { LoadingDialog } from "../basicpages/LoadingDialog";
import { useMediaQuery } from "@mui/material";
import { ScrollToTopButton } from "../ScrollToTopButton";
import styled from "@emotion/styled";
import Chip from "@mui/material/Chip";
import { getIcon } from "../../helper/CategoryIcons";
import { IconButton } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import "./Detail.css";

export default function Detail() {
  const { actID } = useParams();

  const [loading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState({
    id: "",
    title: "",
    shortDescription: "",
    longDescription: "",
    price: 0,
    tipps: [],
    categories: [],
    startImage: {
      small: "",
      large: "",
      alt: "",
    },
    erwImage: {
      small: "",
      large: "",
      alt: "",
    },
  });

  const navigate = useNavigate();

  const mobile = useMediaQuery("(max-width: 768px)");

  const buttonStyle = { width: "fit-content", height: "40px", zIndex: "10", background: "#eee", position: "absolute", top: "1.5rem", left: "1.5rem", fontSize: "12px", borderRadius: "1rem", paddingLeft: "0" };
  if (mobile) {
    buttonStyle.top = "1rem";
    buttonStyle.left = "1rem";
  }
  const iconStyle = { width: "40px", height: "40px", color: "#888" };
  if (mobile) {
    iconStyle.width = "36px";
    iconStyle.height = "36px";
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchData() {
      try {
        const activity = await fetchActivity(actID + ".json", signal);
        setActivityData(activity);
        console.log(JSON.stringify(activity));
        setLoading(false);
      } catch (err) {
        if (err instanceof SyntaxError) {
          navigate("/404");
        }
        console.error(err);
      }
    }
    fetchData();
    return () => {
      // cancel the request before component unmounts
      controller.abort();
    };
  }, [actID, navigate]);

  const GreyChip = styled(Chip)({
    background: "#ededed",
    color: "#686868",
    border: "1px solid #efefef",
    "& .MuiChip-icon": {
      color: "#686868",
    },
  });

  return (
    <>
      {loading === true && <LoadingDialog />}

      <ScrollToTopButton hidden={!mobile} />

      <div className="detail">
        <img
          className="detail-image w-full max-h-[600px] object-cover"
          src={activityData.startImage.large}
          alt={activityData.startImage.alt}
        />

        <section className="relative z-2 md:mt-[-350px] flex">
          <section className="shadow-2xl bg-white px-[7%] pt-20 pb-10 mx-auto rounded-t-3xl w-[100%] md:w-[65%] mt-[-2rem] md:mt-32 relative" >
            <IconButton
              aria-label="navigate back"
              onClick={() => {
                navigate(-1);
              }}
              size="large"
              sx={buttonStyle}
            >
              <ChevronLeft sx={iconStyle} />
              Zurück
            </IconButton>
            <h1 className="text-3xl pb-5">{activityData.title}</h1>
            <section className="my-4 flex flex-wrap gap-2 ">
              {activityData.categories.map((category) => {
                return (
                  <GreyChip
                    sx={{ marginRight: "0.5rem", marginTop: "0.5rem" }}
                    key={category}
                    icon={getIcon(category)}
                    label={category}
                  />
                );
              })}
            </section>
            <p className="pt-5 leading-8 text-justify">
              {activityData.longDescription}
            </p>
            <img
              className=" mx-auto my-12 rounded-3xl"
              src={activityData.erwImage.large}
              alt={activityData.erwImage.alt}
            ></img>
            <h1 className="pt-3 pb-3 text-xl">
              Unsere Reisetipps zu {activityData.title}
            </h1>
            <ul className="list-disc pl-5">
              {activityData.tipps.map((currentTipp) => {
                return <li className="text-cerulean pb-5">{currentTipp}</li>;
              })}
            </ul>

            <div className="fit-cover mx-auto border-[#A97142] border-[3px] rounded-3xl px-10 py-3 mt-10">
              <h1 className="pt-3 pb-3 text-xl">Preisübersicht</h1>
              <ul className="pl-5">
                <li className="text-cerulean pb-3 text-large">
                  {(activityData.price * 0.21).toFixed(2)}€ Reisekosten
                </li>
                <li className="text-cerulean pb-3 text-large">
                  {(activityData.price * 0.01).toFixed(2)}€ Reservierungskosten
                </li>
                <li className="text-cerulean pb-3 text-large">
                  {(activityData.price * 0.02).toFixed(2)}€ Verwaltungskosten
                </li>
                <li className="text-cerulean pb-3 text-large">
                  {(activityData.price * 0.76).toFixed(2)}€ Veranstaltungskosten
                </li>
              </ul>
              <h1 className="pt-3 pb-3 text-xl">
                Gesamtpreis von: {activityData.price}€ pro Person
              </h1>
            </div>

            <button
              type="booking"
              className="block bg-[#A97142] text-white  cursor-pointer text-base my-10 mx-auto px-[40px] py-[15px] rounded-3xl border-[none] standard-secondary-button"
              onClick={() => {
                navigate("/reservate/" + actID);
              }}
            >
              Diese Aktivität Buchen
            </button>
          </section>
        </section>
      </div>
    </>
  );
}
