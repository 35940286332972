import { useState } from "react";
import "./aboutus.css";

export function AboutUs() {
  const [gruenderState, setGruenderState] = useState({
    gates: false,
    jobs: false,
  });

  const toggleGates = () => {
    setGruenderState({ ...gruenderState, gates: !gruenderState.gates });
  };

  const toggleJobs = () => {
    setGruenderState({ ...gruenderState, jobs: !gruenderState.jobs });
  };

  return (
    <div className="background">
      <div className="aboutus relative w-[90%] md:w-[80%] xl:w-[60%] bg-[#e6e6e6] my-[5%] m-auto rounded-[20px] border-0">
        <article className="innerdistance relative w-[85%] mx-auto py-[60px]">
          <section className="title pb-5">
            <h1 className="font-bold text-3xl mb-[30px]">Über Uns</h1>
            <p className="text-justify">
              Willkommen bei Exala! Treffen Sie die Köpfe hinter dem
              Reiseerlebnis: Steve Gates und Bill Jobs. Ihre Vision für
              unkomplizierte Reisen steht im Mittelpunkt unseres Engagements für
              Ihre unvergesslichen Abenteuer. Lassen Sie uns gemeinsam die Welt
              der einfachen und leidenschaftlichen Entdeckungen erkunden!
            </p>
          </section>
          <section>
            <h2 className="gruendertitel text-xl font-bold pb-[15px]">
              Die Gründer
            </h2>
            <article className="gruender gap-8 justify-around flex flex-col md:flex-row">
              <section>
                <h2>Steve Gates</h2>
                <div className="relative">
                  <img
                    src="/assets/images/aboutus/gates_square.jpg"
                    alt="Steve Gates Foto"
                    className="h-[70%]"
                  />

                  <article
                    className={`kurzcv absolute top-0 overflow-auto h-full p-4 bg-black/70 rounded-[20px] ${
                      gruenderState.gates ? "active" : ""
                    }`}
                  >
                    <p className="text-white text-[14px] text-left">
                      Steve Gates: Visionärer Innovator und Unternehmer
                    </p>
                    <p className="text-white text-[14px] text-justify">
                      <br />
                      Seine akademische Reise begann an der Harvard University,
                      wo er Informatik studierte und fundierte Kenntnisse in
                      diesem Bereich erwarb. Früh zeigte sich sein
                      beeindruckendes Innovationspotenzial, das er schließlich
                      gemeinsam mit seinem Partner Bill Jobs bei der Gründung
                      von Exala entfaltete. Der Erfolg von Steve Gates bildet
                      den Grundstein für seine aktuelle Rolle als CEO von Exala,
                      einem führenden Reiseunternehmen, das sich auf einfache
                      und unkomplizierte Kundenerlebnisse spezialisiert hat.
                      Seine Vision, Reisen zu einem mühelosen und bereichernden
                      Erlebnis zu machen, prägt die strategische Ausrichtung von
                      Exala und verspricht unvergessliche Reiseabenteuer.
                    </p>
                  </article>
                </div>
                <button type="button" className="standard-button" onClick={toggleGates}>
                  {" "}
                  Mehr erfahren{" "}
                </button>
              </section>
              <section>
                <h2>Bill Jobs</h2>
                <div className="relative">
                  <img
                    src="/assets/images/aboutus/jobs_square.jpg"
                    alt="Bill Jobs Foto"
                    className="h-[70%]"
                  />
                  <article
                    className={`kurzcv absolute top-0 overflow-auto h-full p-4 bg-black/70 rounded-[20px] ${
                      gruenderState.jobs ? "active" : ""
                    }`}
                  >
                    <p className="text-white  text-[14px] text-left">
                      Bill Jobs: Kreativer Visionär und Unternehmensführer
                    </p>
                    <p className="text-white text-[14px] text-justify">
                      <br />
                      Nach einer erfolgreichen Ära bei Microsoft widmet sich
                      Bill Jobs nun als Mitbegründer von Exala einer neuen
                      Mission. Die Gründung von Exala steht im Zeichen seiner
                      fortwährenden Leidenschaft für innovative Unternehmungen.
                      Als Visionär bei Exala setzt er sein umfassendes Know-how
                      ein, um das Reisen zu einem mühelosen und bereichernden
                      Erlebnis zu transformieren. Seine Führung bei Exala
                      zeichnet sich durch die Vision aus, jedem Kunden
                      unvergessliche Reiseerfahrungen zu bieten, die durch
                      Einfachheit und Kundenzufriedenheit geprägt sind.
                    </p>
                  </article>
                </div>
                <button type="button" className="standard-button" onClick={toggleJobs}>
                  {" "}
                  Mehr erfahren{" "}
                </button>
              </section>
            </article>
          </section>

          <section className="pt-[50px] pb-5">
            <h2> Unsere Vision</h2>
            <p className="text-justify">
              Exala's Vision ist es, die Art und Weise, wie Menschen die Welt
              bereisen, zu revolutionieren. Wir streben danach, die erste Wahl
              für Reisende zu sein, die sich nach mühelosen und unkomplizierten
              Abenteuern sehnen. Unsere Vision ist es, Reisen zu einem nahtlosen
              und bereichernden Erlebnis zu machen, bei dem jeder Moment die
              Freude des Entdeckens und Genießens in sich trägt.
              <br />
              <br />
              Bei Exala glauben wir an eine Welt, in der das Reisen nicht nur
              ein Ziel ist, sondern ein sinnvolles Erlebnis, das das Leben
              bereichert und die Verbindung zu verschiedenen Kulturen und Orten
              stärkt. Unsere Vision ist es, unseren Kunden nicht nur Reisen
              anzubieten, sondern auch unvergessliche Erinnerungen zu schaffen,
              die ein Leben lang anhalten.
            </p>
          </section>
          <section className="whyandratings flex gap-y-[50px] gap-x-[5%] pt-[50px] flex-col md:flex-row">
            <section className="leftblock">
              <h2>Warum Exala?</h2>
              <p>
                Wir bieten Ihnen nicht nur ein breites Spektrum an
                faszinierenden Reisezielen, sondern setzen auch alles daran,
                Ihre Reiseplanung so einfach wie möglich zu gestalten. Von der
                Buchung bis zur Rückkehr kümmern wir uns um jedes Detail, damit
                Sie sich zurücklehnen und jede Minute Ihres Abenteuers genießen
                können.
              </p>
            </section>
            <section className="rightblock md:w-2/5">
              <h2>Bewertungen</h2>

              <img
                src="\assets\images\aboutus\googlebewertung.jpg"
                alt="Google Bewertungen"
              ></img>
            </section>
          </section>
        </article>
      </div>
    </div>
  );
}
