import { Link } from "react-router-dom";

export default function Footer() {

    return (
        <footer className="bg-cyprus p-8">
            <section className="row-1 flex flex-col md:justify-between md:flex-row"> {/* Hier ist eine Media Query */}
                <Link to="/" className="h-[40px] w-fit mx-auto md:mx-0 my-auto">
                    <img src="/assets/images/logo/SVG/Logo_weiss.svg" alt="Exala als Logo, mit Flugzeug als X"
                     className="h-[40px]"/>
                </Link>
                <div className="social-media mt-6 md:mt-0"> {/* Hier ist eine Media Query */}
                    <h2 className="text-white font-comfortaa text-xl mb-2 text-center">Besuche uns auch hier</h2> 
                    <section className="brands flex flex-row gap-4 justify-around max-w-md mx-auto">
                        <Link to="https://de-de.facebook.com/" target="_blank" rel="norefferer">
                            <img src="/assets/images/icons/Facebook_Logo_Secondary.png" width={30} height={30}
                                 alt="Facebook-Logo Link"/>
                        </Link>

                        <Link to="https://www.instagram.com/" target="_blank" rel="norefferer">
                            <img src="/assets/images/icons/Instagram_Glyph_White.svg" width={30} height={30}
                                 alt="Instagram-Logo Link"/>
                        </Link>
                        
                        <Link to="https://twitter.com/" target="_blank" rel="norefferer">
                            <img src="/assets/images/icons/X_Logo.svg" width={30} height={30} alt="X-Logo Link"/>
                        </Link>

                        <Link to="https://www.pinterest.de/" target="_blank" rel="noreferrer">
                            <img src="/assets/images/icons/P-Badge-Mochimalist-White-000.svg" width={30} height={30}
                                 alt="Pinterest-Logo Link"/>
                        </Link>
                    </section>
                </div>
            </section>
            <section className="links text-white mt-10 flex flex-row gap-4">
                <Link className="font-comfortaa cursor-pointer" to="/about-us">Über Uns</Link>
                <Link className="font-comfortaa cursor-pointer" to="https://www.htwk-leipzig.de/hochschule/kontakt/impressum">Impressum</Link>
                <Link className="font-comfortaa cursor-pointer" to="/kontakt">Kontakt</Link>
            </section>
        </footer>
    )
}