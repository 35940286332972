import { useEffect, useRef, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import "./navbar.css";
import { Link, useNavigate } from "react-router-dom";

export default function Navbar() {
  //hier ist eine Mediaquery
  const mobile = useMediaQuery("(max-width: 768px)");

  const navigate = useNavigate();

  const [scroll, setScroll] = useState(false);

  const [open, setOpen] = useState(false);

  const links = [
    { name: "Startseite", link: "/" },
    { name: "Alle Angebote", link: "/angebote" },
    {
      name: "Kontakt",
      link: "/kontakt",
    },
    { name: "Über Uns", link: "/about-us" },
  ];

  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const changeNavbar = () => {
    const shouldCloseMenu = scroll && open;

    if (
      shouldCloseMenu ||
      (!scroll && window.scrollY > 77.5) ||
      (scroll && window.scrollY > 22.5)
    ) {
      setScroll(true);
      setOpen(false);
    } else {
      setScroll(false);
    }
  };

  const getSecondaryList = () => {
    return (
      <ul className="navbar list-none m-0 p-0 text-white my-0 flex flex-row">
        {links.map((current) => {
          return (
            <li className={"my-auto"} key={current.link}>
              <Link
                className={
                  "font-comfortaa cursor-pointer p-4" +
                  (current.link === window.location.pathname ? " active" : "")
                }
                to={current.link}
              >
                {current.name}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  };

  //EventListener für scroll normal zu sticky
  window.addEventListener("scroll", changeNavbar);

  function GetMenuIcon() {
    if (scroll && open) {
      return (
        <div className="menu-container my-auto" ref={menuRef}>
          <MenuIcon
            className="my-auto text-white cursor-pointer"
            sx={{ width: "45px", height: "45px", viewBox: "0 0 32 32" }}
            onClick={() => {
              setOpen(!open);
            }}
          />
          <div
            className={`dropdown-menu-scroll ${open ? "active" : "inactive"}`}
          >
            <ul>
              {links.map((current) => {
                return <DropDownItem text={current.name} link={current.link} />;
              })}
            </ul>
          </div>
        </div>
      );
    } else {
      return (
        <div className="menu-container my-auto" ref={menuRef}>
          <MenuIcon
            className="my-auto text-white cursor-pointer"
            sx={{ width: "45px", height: "45px", viewBox: "0 0 32 32" }}
            onClick={() => {
              setOpen(!open);
            }}
          />
          <div
            className={`${scroll ? "dropdown-menu-scroll" : "dropdown-menu"} ${
              open ? "active" : "inactive"
            }`}
          >
            <ul>
              {links.map((current) => {
                return <DropDownItem text={current.name} link={current.link} />;
              })}
            </ul>
          </div>
        </div>
      );
    }
  }

  function DropDownItem({ link, text }) {
    return (
      <li
        className="dropdown"
        onClick={() => {
          navigate(link);
        }}
      >
        <Link to={link}>{text}</Link>
      </li>
    );
  }

  if (scroll) {
    return (
      <>
        <section className={`bg-white h-[70px] w-full flex flex-row md:px-40`}>
          <Link to="/" className="max-h-[55px] my-auto mx-auto md:mx-0">
            <img
              src="/assets/images/logo/100h/Logo_schwarz_akzent_schrift_100h.png"
              alt="Exala als Logo, mit Flugzeug als X"
              className="max-h-[55px]"
            />
          </Link>
        </section>
        <nav
          className={`bg-cerulean h-[55px] w-full flex flex-row justify-between fixed top-0 z-[100000] px-6 sm:px-24 md:px-16 lg:px-40`}
        >
          {/* Hier ist eine Mediaquery */}
          <Link to="/" className="h-[35px] my-auto">
            <img
              src="/assets/images/logo/SVG/Logo_weiss.svg"
              alt="Exala als Logo, mit Flugzeug als X"
              className="h-[35px]"
            />
          </Link>
          {mobile ? GetMenuIcon() : getSecondaryList()}
        </nav>
      </>
    );
  } else {
    return (
      <nav className="z-[100000]">
        <section className={`bg-white h-[70px] w-full flex flex-row md:px-40`}>
          {/* Hier ist eine Mediaquery*/}
          <Link to="/" className="max-h-[55px] my-auto mx-auto md:mx-0">
            <img
              src="/assets/images/logo/100h/Logo_schwarz_akzent_schrift_100h.png"
              alt="Exala als Logo, mit Flugzeug als X"
              className="max-h-[55px]"
            />
          </Link>
        </section>
        {/* Hier ist eine Mediaquery*/}
        <section
          className={`bg-cerulean h-[55px] w-full flex flex-row  justify-end px-12 md:px-24 lg:px-40`}
        >
          {mobile ? GetMenuIcon() : getSecondaryList()}
        </section>
      </nav>
    );
  }
}
