import "./LoadingDialog.css"
export function LoadingDialog() {
   return (
       <div className={"bg-gray-600/50 w-full h-full z-[1000] fixed top-0 left-0 flex justify-center items-center"}>
           <article className="flex flex-col w-[300px] bg-white h-fit p-8 rounded-xl shadow-2xl justify-center items-center">
               <span className="loader h-16 w-16"></span>
               <p className="text-2xl text-center mt-4">Bitte warten...</p>
           </article>
       </div>

   )
}