import SearchBox from "../search/SearchBox";
import SearchResult from "../searchResult/SearchResult";
import {fetchActivities, sortAndFilterActivities} from "../../helper/ResultCalc";
import {useEffect, useState} from "react";
import {LoadingDialog} from "../basicpages/LoadingDialog";
import { useSearchParams } from "react-router-dom";
import { ScrollToTopButton } from "../ScrollToTopButton";
import { Fab, useMediaQuery } from '@mui/material';


const chipsDefault = [
    {
      title: "Region",
      chips: [
        { label: "Berge"},
        { label: "Stadt"},
        { label: "Strand"},
      ],
    },
    {
      title: "Reiseziel",
      chips: [
        { label: "Abenteuer"},
        { label: "Entspannung"},
        { label: "Entdecken"},
        { label: "Natur"},
      ],
    },
    {
      title: "Reiseart",
      chips: [
        { label: "Allein"},
        { label: "Paar"},
        { label: "Gruppe"},
      ],
    },
    {
      title: "Temperatur",
      chips: [
        { label: "Warm"},
        { label: "Kalt"},
      ],
    },
    {
      title: "Sonstige",
      chips: [
        {label: "Barrierefrei"},
      ]
    }
  ];

export function Landingpage() {

    let [searchParams, setSearchParams] = useSearchParams();

    const mobile = useMediaQuery('(max-width: 768px)')

    const [loading, setLoading] = useState(true);

    const [rawActivities, setRawActivities] = useState(null);

    const [currentDisplay, setCurrentDisplay] = useState([]);

    const [displayText, setDisplayText] = useState("Unsere Empfehlungen");

    const [chips, setChips] = useState(getDefaultChips());

    function getDefaultChips() {
        return chipsDefault.map((currentCat) => ({
          ...currentCat,
          chips: currentCat.chips.map((chip) => ({
            ...chip,
            checked: !!searchParams.get(chip.label),
          })),
        }));
      }

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        async function fetchData() {
            try {
                const activities = await fetchActivities(signal);
                setRawActivities(activities.all);
                if (Array.from(searchParams.keys()).length > 0) {
                    sortAndFilterActivities(activities.all, Array.from(searchParams.keys()), (filtered) => {
                        setCurrentDisplay(filtered);
                        setDisplayText("Ergebnisse");
                    });
                } else {
                    setCurrentDisplay(activities.recommendations);
                }
                setDisplayText("Unsere Empfehlungen");
                setLoading(false);
            } catch (err) {
                console.error(err)
            }
        }

        if (!rawActivities) {
            fetchData();
        }
        return () => {
            // cancel the request before component unmounts
            controller.abort();
        };
    }, [rawActivities]);

    

    const handleSearchResult = (result) => {
        let checkedCats = [];
        result.categories.forEach((currentCat) => {
            checkedCats = checkedCats.concat(
                currentCat.chips.filter(
                    (currentChip) => currentChip.checked
                ).map((temp) => temp.label));
        })

        const newParams = checkedCats.map((current) => {return {[current]: true}}).reduce((accumulator, currentObject) => {
            return { ...accumulator, ...currentObject };
          }, {})

        setSearchParams(newParams);
        setChips(result.newChips);

        sortAndFilterActivities(rawActivities, checkedCats, (filtered) => {
            setCurrentDisplay(filtered);
            setDisplayText("Ergebnisse");
        });
    };


    return (
        <>
            <ScrollToTopButton hidden={!mobile}/>

            {loading === true && (<LoadingDialog/>)}
            <article
                className="font-poppins flex gap-6 flex-col bg-landingBackground bg-cover bg-center sm:px-16 py-[2.5vw] md:py-32">
                {/*Hier sind Mediaqueries*/}
                <div
                    className="searchbox bg-slate-100/40 backdrop-blur-md border-slate-50/30 border-[1px] rounded-xl min-h-[300px] shadow-xl p-6 sm:p-8 mx-auto w-[95%] sm:w-[90%] md:w-[80%] max-w-[1100px]">
                    {/*bedingtes Rendering*/}
                    <h1 className="text-black text-center text-2xl mt-4 mb-8 ">
                        Plane jetzt Deine nächste Reise
                    </h1>
                    <SearchBox updateAppState={handleSearchResult} chips={chips}/>
                    <SearchResult displayData={currentDisplay} displayText={displayText}/>
                </div>
            </article>
        </>
    )
}