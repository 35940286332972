export function PageNotFound() {
    return (
        <article className="flex gap-6 flex-col bg-white sm:px-16 py-32">
            {/*Hier sind Mediaqueries*/}
            

                <h1 className="text-center text-4xl leading-loose">Es hat einen Fehler gegeben. Die von Ihnen angesteuerte Webseite existiert nicht.</h1>
                <img src="/assets/images/icons/sad_face.svg" alt="Trauriger Smiley"
                     className="h-[50%]">
                </img>

        </article>
    )
}