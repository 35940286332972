import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import { Fab } from '@mui/material';

export function ScrollToTopButton({hidden}) {

    const [visible, setVisibility] = useState(false);

    window.addEventListener("scroll", () => {
        setVisibility(window.scrollY > 130);
    })

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
       };
       if (hidden) {
        return (<></>);
       }
    
    return (
        <Fab style={{position: "fixed", bottom: "1rem", right: "1rem", transition: "all", transitionDuration: "300ms", opacity: visible ? 1 : 0, background: "#046C95"}} color="primary" aria-label="scroll-to-top" onClick={scrollToTop}>
            <KeyboardArrowUpIcon style={{width: "36px", height: "36px"}}/>
        </Fab>
    )
}