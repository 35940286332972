export function sortAndFilterActivities(rawData, requestedCategories, cb) {
    const filteredCategories = [];

    rawData.forEach((currentActivity) => {
        if (requestedCategories && currentActivity.categories) {
            const requestedInAct = countCommonItems(requestedCategories, currentActivity.categories) / requestedCategories.length; //Verhältnis Wie viele requested gibt es in der Aktivität zu insgesamt requested
            const actInRequested = countCommonItems(currentActivity.categories, requestedCategories) / currentActivity.categories.length;//Verhältnis Wie viele der Aktivität gibt es in den requested zu insgesamt Aktivität

            const tempResult = (requestedInAct + actInRequested) / 2; //Durchschnitt beider Werte

            if (tempResult > 0.4) {
                console.debug(tempResult);
                filteredCategories.push({...currentActivity, score: tempResult});
            }
        }
    })

    filteredCategories.sort((a, b) => {
        return b.score - a.score
    });

    filteredCategories.splice(8);
    cb(filteredCategories);
}

function countCommonItems(array1, array2) {
    let count = 0;

    array1.forEach(item => {
        if (array2.includes(item)) {
            count++;
        }
    });

    return count;
}

export async function fetchActivity(activity, signal) {
    const activityPath = "/assets/activities/";
    return await (await fetch(activityPath + activity, {signal: signal})).json();
}

export async function fetchActivities(signal) {
    const activityPath = "/assets/activities/";

    try {
        //config laden
        const response = await fetch(activityPath + "activityConfig.json", {signal});
        const json = await response.json();

        console.debug(json);

        //aktivitäten laden
        const activityPromises = json.all.map(async (current) => {
            return await (await fetch(activityPath + current, {signal})).json();
        });

        const allActivities = await Promise.all(activityPromises);

        //Empfehlungen laden
        const recommendedPromises = json.recommendations.map(async (current) => {
            return await (await fetch(activityPath + current, {signal})).json();
        });

        const recommendedActivities = await Promise.all(recommendedPromises);

        return { recommendations: recommendedActivities, all: allActivities };
    } catch (error) {
        console.error("Error fetching activities:", error);
        throw error;
    }
}